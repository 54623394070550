.header {
    width: calc(100% - 170px);
    height: 100px;
    position: fixed;
    top: 0;
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 85px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: normal;
    color: #fff;
    z-index: 5;
}

.phoneSvg {
    width: 24px;
    height: 24px;
    margin: 3px 16px 0 0px;
    object-fit: contain;
}

.section {
    color: white;
    padding: 20px;
    cursor: pointer;
    border-radius: 20px;
}

.section:hover{
    /* color: aliceblue; */
    background-color: grey;
}

.mosImg {
    width: 167px;
    height: 27px;
    margin: 0 111px 0 0;
    object-fit: contain;
    cursor: pointer;
}

.call {
    color: white;
    padding: 15px;
    border-radius: 20px;
    display: flex;
    align-items: center;
}
.callTo {
    padding: 10px;
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.callTo:hover {
    color: aliceblue;
    background-color: grey;
    border-radius: 20px;
}

.sidebarToggle {
    display: none;
}

.hide {
    display: none!important;
}

.sections {
    display: flex;
    align-items: center;
}

.section > a {
    text-decoration: none;
    color: white;
}

@media only screen and (max-width: 1250px) {
    .sections {
        display: none;
    }
    .call {
        display: none;
    }
    .sidebar {
        background-color: black;
        width: 100vw;
        height: 100vh;
        position: absolute;
        z-index: 6;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
    }

    .sidebarHead {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 100px;
    }

    .sidebarColumn {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sidebarToggle {
        width: 24px;
        height: 24px;
        display: block;
        color: white;
    }

    .mosImg {
        margin-right: 0;
    }

    .sdCall {
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 605px) {
    .header {
        width: calc(100% - 50px);
        padding: 0 25px;
    }
}
