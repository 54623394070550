html, body, #root {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: clip;
}

body {
  margin: 0;
  font-family: SFPro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

@font-face {
  font-family: 'SFPro';
  src: local('SFPro'), url(../SFPro.ttf) format('truetype');
}
