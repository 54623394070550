.prices {
    margin-top: 100px;
    width: calc(100% - 80px);
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    color: white;
    overflow: auto;
}

.ogColor {
    color: #f85739;
    font-weight: bold;
}

.highlighted, .priceSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.pricesColumn {
    margin-top: 20px;
    max-width: 800px;
    width: 100%;
}

.priceHeader {
    font-size: 68px;
}

.highlighted {
    font-weight: bold;
    color: #f85739;
    font-size: 36px;
}

.subHead {
    margin-top: 20px;
    font-size: 36px;
    font-weight: 500;
}

@media only screen and (max-width: 605px) {
    .highlighted, .priceSection {
        align-items: center;
    }

    .priceHeader {
        font-size: 48px;
    }
}
