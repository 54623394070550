.secondSection {
    width: calc(100% - 160px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px;
}

.row {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
}

.description {
    width: 273px;
    height: 85px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
}

.optionImgContainer {
    display: flex;
    flex-direction: column;
    width: 305px;
    border-radius: 4px;
    background-color: #ececec;
    margin-top: 16px;
}

.subHeader {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #202020;
    padding-bottom: 64px;
}

.firstLine {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: normal;
    text-align: center;
    color: #1a1a1a;
}

.secondLine {
    margin: 24px 0 0 0;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: normal;
    text-align: center;
    color: #1a1a1a;
}

.m-t-45 {
    margin-top: 45px;
}

.divider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
}