/* .reviews {
    height: 500px;
    width: 100%;
} */

.reviewHead {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    color: #000;
}

.iframeContainer {
    margin-top: 20px;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 100%;
}

.iframeContainer > iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}