.firstSection {
    width: 100%;
    height: 100%;
    /* background-attachment: fixed; */
    background-image: url('../FirstSection.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    /* aspect-ratio: 1451 / 837; */
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.fts27 {
    font-size: 27px!important;
}
.firstSection::before {
    background-color: rgba(0, 0, 0, 0.8);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.allDay {
    width: fit-content;
    padding-bottom: 32px;
    margin-top: 200px;
    border-bottom: 1px solid;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    z-index: 2;
    border-color: #f85739;
}

.outDoorCut {
    margin-top: 32px;
    z-index: 2;
    font-size: 68px;
    font-weight: normal;
    text-align: center;
    color: #fff;
}

.orangeText {
    font-size: 68px;
    font-weight: normal;
    text-align: center;
    color: #fff;
    display: inline-block;
    z-index: 2;
}

.orangeText > span {
    color: orangered;
    font-weight: bold;
    color: #f85739;
    font-size: 68px;
    text-align: center;
}

.sectionFoot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    max-width: 900px;
    width: 100%;
    z-index: 2;
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 10px;
    color: #fff;
    padding-bottom: 48px;
}

.sectionFoot > span {
    padding: 20px;
} 

.imgContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 150px;
}

.button {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    text-align: center;
    color: #fff;
    font-weight: 400;
    margin-top: 50px;
    width: 268px;
    height: 52px;
    padding: 16px 20px;
    border-radius: 4px;
    background-color: #f85739;
    z-index: 2;
    border-color: transparent;
    cursor: pointer;
}

@media only screen and (max-width: 710px) {
    .sectionFoot {
        justify-content: center;
        flex-wrap: wrap;
    }
    .sectionFoot > span {
        margin-top: 20px;
    };
}

@media only screen and (max-width: 1170px) {
    .sectionFoot {
        margin-top: 40px;
    }
}