.advantageHeader {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #fff;
    margin: 80px 0;
    z-index: 2;
}

.thirdSection {
    width: calc(100% - 40px);
    height: 655px;
    background-image: url('../thirdSection.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 1451/837;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-attachment: fixed;
    padding: 0 20px;
    position: relative;
}

.thirdSection::before {
    background-color: rgba(0, 0, 0, 0.8);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.advantageRow {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 50px;
}

.advantage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 184px;
    z-index: 2;
    flex: 0 1 calc(20% - 8px);
}

.advantage > span {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    text-wrap: nowrap;
}

.m-t-32 {
    margin-top: 32px;
}

.advantageImg {
    width: 85px;
    height: 85px;
    object-fit: contain;
}

@media only screen and (max-width: 715px) {
    .thirdSection {
        height: fit-content;
        padding-bottom: 80px;
    }
}

@media only screen and (max-width: 470px) {
    .advantage > span {
        font-size: 14px;
    }
    .advantageImg {
        width: 65px;
        height: 65px;
    }
}