.fourthSection {
    width: 100%;
    height: 100%;
    display: flex;
    background-image: url('../../public/contact-us-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    align-items: center;
    padding: 100px 0 50px;
}

.grpTxt > a, .group > a {
    text-decoration: none;
    color: white;
    display: flex;
    flex-direction: column;
}

.secondGroup, .grp {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}

.address {
    margin-top: 16px;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}

.txtInput {
    border: 1px solid transparent;
    margin-right: 16px;
    padding: 16px 112px 16px 16px;
    border-radius: 4px;
    height: 18px;
    background-color: #f2f2f2;

}

.txtInput::placeholder {
    opacity: 0.6;
    font-size: 16px;
    color: #000000;
}

.formContainer > button {
    border: none;
    cursor: pointer;
    width: 188px;
    height: 51px;
    margin: 0px 0 0 12px;
    padding: 16px 24px;
    border-radius: 4px;
    background-color: #f85739;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #fff;
  
}

.formContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 32px;
    gap: 10px;
}

.leaveMessageHeader {
    font-size: 26px;
    font-weight: 500;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}

.inpContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.contactUsContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 128px);
    align-items: center;
    padding: 64px;
    color: white;
    align-self: center;
    text-align: left;

    border-radius: 4px;
    box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.29);
    background-color: rgba(4, 4, 4, 0.85);
}

.groupContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    margin-top: 20px;
    flex-wrap: wrap;
}

.contactUsHeader {
    margin: 0 35px 0px 0px;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 1.44px;
    text-align: center;
    color: #fff;
}

.group {
    display: flex;
    flex-direction: column;
}

.groupName {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
}

.logoImg {
    width: 39px;
    height: 39px;
    cursor: pointer;
}

.grpTxt {
    color: white;
    text-decoration: none;
}

@media only screen and (max-width: 605px) {
    .m-t-10 {
        margin-top: 10px;
    }
}